import { Component, ChangeDetectionStrategy, Input, OnInit, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

import { UserInfoModalComponent } from '../user-info-modal/user-info-modal.component';
import { MenuItem, CommonStateService } from '../../../state';

@Component({
    selector: '[cal-menu]',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuComponent implements OnInit, OnDestroy {

    @Input('menu') menuItems: MenuItem[];

    @Input() profile: any;

    @Input() activeMenu: MenuItem;
    subscriptions = new Subscription();

    constructor(private modalService: NgbModal,
        private commonService: CommonStateService,
        private router: Router) {

    }

    ngOnInit(): void {
        this.subscriptions.add(this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const url = event.url.substring(1);
                const findMenuByUrlFn = (menus: MenuItem[], url: string) => {
                    for (let i = 0; i < menus.length; i++) {
                        if (menus[i].url === url) return menus[i];
                        if (menus[i].subItems) {
                            const result = findMenuByUrlFn(menus[i].subItems, url);
                            if (result !== null) return result;
                        }
                    }
                    return null;
                };
                const menu = findMenuByUrlFn(this.menuItems, url);
                if (menu !== null) {
                    this.commonService.updateActiveMenu(menu);
                }
            }
        }));
    }

    ngOnDestroy() { this.subscriptions.unsubscribe(); }

    onMenuClicked(topMenu: MenuItem, clickedMenu: MenuItem, event: Event) {
        if (clickedMenu.subItems && clickedMenu.subItems.length) {
            this.openMenuList(clickedMenu, event);
        } else {
            this.commonService.updateActiveMenu(clickedMenu);
        }
        event.stopPropagation();
        return;
    }

    openMenuList(clickedMenu: MenuItem, event: Event) {
        if (this.isActive(clickedMenu)) {
            this.commonService.updateActiveMenu(null);
        } else {
            this.commonService.updateActiveMenu(clickedMenu);
        }
    }

    isActive(menu: MenuItem): boolean {
        if (this.activeMenu === undefined || this.activeMenu === null) return false;
        const menuParentPath: MenuItem[] = [];
        this.getMenuParentPath(this.activeMenu, this.menuItems, menuParentPath);
        return menuParentPath.findIndex(x => x.id === menu.id) >= 0;
    }

    getMenuParentPath(menu: MenuItem, allMenus: MenuItem[], resultArray: MenuItem[]): boolean {
        for (let i = 0; i < allMenus.length; i++) {
            if (allMenus[i].id === menu.id) {
                resultArray.push(allMenus[i]);
                return true;
            }
            if (allMenus[i].subItems) {
                if (this.getMenuParentPath(menu, allMenus[i].subItems, resultArray)) {
                    resultArray.push(allMenus[i]);
                    return true;
                }
            }
        }
        return false;
    }

    userInfoClicked() {
        const modalInstance = this.modalService.open(UserInfoModalComponent, {backdrop: 'static', keyboard: false});
        modalInstance.componentInstance.profile = this.profile;
    }
}
