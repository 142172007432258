import { Injectable } from '@angular/core';
import { Store, State } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';

import { AppState } from '../app.state';
import {
    LoadComponentAction,
    ChangeComponentPageAction,
    ChangeComponentSortExpressionAction,
    ChangeComponentFilterExpressionAction,
    ChangeComponentSearchFormAction,
    ChangeComponentResultsTotalCountAction,
    LoadProductIndividualComponentsAction
} from './actions';

import { CalsiumDateFormatterPipe } from '../../app/shared/calsium-date.pipe';
import { ComponentIndividualApiService } from '../../api/services/component-individual-api.service';
import { ComponentIndividual } from './state-models/component-individual';
import { Paging } from '..';
import { ComponentSearchForm } from './state-models/component-search-form';
import { Loadable } from '../common/loadable';
import { toNumber } from 'lodash';

@Injectable()
export class ComponentStateService {
    constructor(private appStore: Store<AppState>,
        private appState: State<AppState>,
        private apiService: ComponentIndividualApiService,
        private datePipe: CalsiumDateFormatterPipe) { }

    @Loadable()
    async loadComponentData(searchForm: ComponentSearchForm, paging: Paging, sortExpression: string, filterExpression: string): Promise<void> {
        const payload = {
            paging: {
                page: paging.page,
                offset: paging.offset
            },
            order: sortExpression,
            filter: filterExpression,
        };
        if (searchForm != null && searchForm.fromDate != null) {
            payload['from'] = new Date(searchForm.fromDate).toISOString()
        }
        if (searchForm != null && searchForm.toDate != null) {
            payload['to'] = new Date(searchForm.toDate).toISOString()
        }
        if (searchForm != null && searchForm.scaniaUnit != null) {
            payload['scaniaUnit'] = _.toNumber(searchForm.scaniaUnit);
        }
        const components = await this.apiService.getComponentIndividuals(payload);
        this.appStore.dispatch(new LoadComponentAction(components.list));
        this.appStore.dispatch(new ChangeComponentResultsTotalCountAction(components.totalCount));
    }

    async loadComponentsByProductIndividual(productIndividualId: number, allocationScaniaUnitId?: number): Promise<void> {
        this.appStore.dispatch(new LoadProductIndividualComponentsAction(null));
        const payload = {
            productIndividualId:toNumber(productIndividualId),
            allocationScaniaUnitId:toNumber(allocationScaniaUnitId)
        };
        const components = await this.apiService.getComponentsByProductIndividual(payload);
        this.appStore.dispatch(new LoadProductIndividualComponentsAction(components));
    }

    // =============================================
    getComponents(): Observable<ComponentIndividual[]> {
        return this.appStore
            .select(state => state.component.component)
            .pipe(map(components => _.values(components)));
    }

    getProductIndividualComponents(): Observable<ComponentIndividual[]> {
        return this.appStore
            .select(state => state.component.productIndividualComponents);
    }

    exportComponentData(scaniaUnit: string = null): Promise<any> {
        const searchForm = <ComponentSearchForm>this.appState.getValue().component.componentSearchForm;
        const payload = {};
        let fileName = `${scaniaUnit}`;
        if (searchForm != null && searchForm.fromDate != null) {
            payload['from'] = new Date(searchForm.fromDate).toISOString();
            fileName += `_${searchForm.fromDate}`;
        }
        if (searchForm != null && searchForm.toDate != null) {
            payload['to'] = new Date(searchForm.toDate).toISOString();
            fileName += `_${searchForm.toDate}`;
        }
        if (searchForm != null && searchForm.scaniaUnit != null) {
            payload['scaniaUnit'] = toNumber(searchForm.scaniaUnit);
        }
        return this.apiService.exportComponentIndividuals(payload, fileName);
    }

    getComponentPaging(): Observable<Paging> {
        return this.appStore.select(state => state.component.componentPaging);
    }

    goToComponentPage(page: number) {
        this.appStore.dispatch(new ChangeComponentPageAction(page));
    }

    getComponentTotalCount(): Observable<number> {
        return this.appStore.select(state => state.component.componentTotalCount);
    }

    getComponentSortingExpression(): Observable<string> {
        return this.appStore.select(state => state.component.componentSortExpression);
    }

    sortComponent(sortExpression: string) {
        this.appStore.dispatch(new ChangeComponentSortExpressionAction(sortExpression));
    }

    clearFilterAndSorting() {
        [
            new ChangeComponentSortExpressionAction(null),
            new ChangeComponentFilterExpressionAction(null)
        ].forEach(action => this.appStore.dispatch(action));
    }

    getComponentFilterExpression(): Observable<string> {
        return this.appStore.select(state => state.component.componentFilterExpression);
    }

    filterComponent(filterExpression: string) {
        this.appStore.dispatch(new ChangeComponentFilterExpressionAction(filterExpression));
    }

    getComponentSearchForm(): Observable<ComponentSearchForm> {
        return this.appStore.select(state => state.component.componentSearchForm);
    }

    changeComponentSearchForm(searchForm: ComponentSearchForm) {
        this.appStore.dispatch(new ChangeComponentSearchFormAction(searchForm));
    }
}
