module.exports = [
    {
        AllocationUnits: ["MSB", "MZB", "MX", "BRBU"],
        Fields: `
            chassisNumber
            productIndividualNumber
            countryCode
            Unique_Design_Number: uniqueDesignNumber
            FFU_Request_Number: fitForUseNumber
            SOrder_Number: specialOrderNumber
            engineVehicle
            batteryVehicle
            axle
            gearboxVehicle
            electricPropVehicle
            chassisBus: finalAssemblyComponentDeliveryValue
            totalCost
            typeDescription
            busType
            responsibilityCode
            materialValue
            productionValueDetails {
                key
                value
            }
            variantDetails {
                Variant_Family: variantFamily
                Variant_Option: variantOption
                Variant_Family_Desc: variantFamilyDescription
                Variant_Option_Desc: variantOptionDescription
            }
            productionDate
            invoiceDate
        `
    },
    {
        AllocationUnits: ["MZ", "MZK", "MA", "MS"],
        Fields: `
            chassisNumber
            productIndividualNumber
            countryCode
            Unique_Design_Number: uniqueDesignNumber
            FFU_Request_Number: fitForUseNumber
            SOrder_Number: specialOrderNumber
            engineVehicle
            batteryVehicle
            axle
            gearboxVehicle
            cab
            painted
            electricPropVehicle
            chassisTruck: finalAssemblyComponentDeliveryValue
            totalCost
            typeDescription
            materialValue
            productionValueDetails {
                key
                value
            }
            variantDetails {
                Variant_Family: variantFamily
                Variant_Option: variantOption
                Variant_Family_Desc: variantFamilyDescription
                Variant_Option_Desc: variantOptionDescription
            }
            productionDate
            invoiceDate
        `
    },
    {
        AllocationUnits: ["BRCH"],
        Fields: `
            chassisNumber
            productIndividualNumber
            countryCode
            Unique_Design_Number: uniqueDesignNumber
            FFU_Request_Number: fitForUseNumber
            SOrder_Number: specialOrderNumber
            engineVehicle
            batteryVehicle
            gosParts
            axle
            gearboxVehicle
            cab
            painted
            chassisTruck: finalAssemblyComponentDeliveryValue
            totalCost
            typeDescription
            materialValue
            productionValueDetails {
                key
                value
            }
            variantDetails {
                Variant_Family: variantFamily
                Variant_Option: variantOption
                Variant_Family_Desc: variantFamilyDescription
                Variant_Option_Desc: variantOptionDescription
            }
            productionDate
            invoiceDate
        `
    },
    {
        AllocationUnits: ["IM", "BRIM"],
        Fields: `
            serialNumber
            productIndividualNumber
            countryCode
            Unique_Design_Number: uniqueDesignNumber
            FFU_Request_Number: fitForUseNumber
            SOrder_Number: specialOrderNumber
            engineIM: finalAssemblyComponentDeliveryValue
            electricPropVehicle
            totalCost
            typeDescription
            materialValue
            productionValueDetails {
                key
                value
            }
            variantDetails {
                Variant_Family: variantFamily
                Variant_Option: variantOption
                Variant_Family_Desc: variantFamilyDescription
                Variant_Option_Desc: variantOptionDescription
            }
            productionDate
            invoiceDate
        `
    },
    {
        AllocationUnits: ["DGE"],
        Fields: `
            serialNumber
            productIndividualNumber
            countryCode
            Unique_Design_Number: uniqueDesignNumber
            FFU_Request_Number: fitForUseNumber
            SOrder_Number: specialOrderNumber
            gearboxExternal: finalAssemblyComponentDeliveryValue
            totalCost
            typeDescription
            materialValue
            productionValueDetails {
                key
                value
            }
            variantDetails {
                Variant_Family: variantFamily
                Variant_Option: variantOption
                Variant_Family_Desc: variantFamilyDescription
                Variant_Option_Desc: variantOptionDescription
            }
            productionDate
            invoiceDate
        `
    },
    {
        AllocationUnits: null,
        Fields: `
            chassisNumber
            serialNumber
            productIndividualNumber
            countryCode
            Unique_Design_Number: uniqueDesignNumber
            FFU_Request_Number: fitForUseNumber
            SOrder_Number: specialOrderNumber
            totalCost
            typeDescription
            materialValue
            productionValueDetails {
                key
                value
            }
            variantDetails {
                Variant_Family: variantFamily
                Variant_Option: variantOption
                Variant_Family_Desc: variantFamilyDescription
                Variant_Option_Desc: variantOptionDescription
            }
            productionDate
            invoiceDate
        `
    }
];