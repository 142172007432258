import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as _ from 'lodash';

import { AppState } from '../app.state';
import {
    LoadProductProductionValueAction, LoadProductIndividual, LoadProductManualProductionValueAction
} from './actions';

import { ProductIndividualApiService } from 'src/api/services/product-individual-api.service';
import { ComponentTypeProductionValue } from './state-models/component-type-production-value';
import { ProductIndividual } from './state-models/product-individual';
import { ComponentTypeManualProductionValue } from './state-models/component-type-manual-production-value';

@Injectable()
export class ProductIndividualStateService {
    constructor(private appStore: Store<AppState>,
        private apiService: ProductIndividualApiService) { }

    async loadProductIndividualProductionValues(productIndividualId: number, allocationScaniaUnitId?: number): Promise<void> {
        const payload = {
            productIndividualId:_.toNumber(productIndividualId),
            allocationScaniaUnitId:_.toNumber(allocationScaniaUnitId)
        };
        const productionValues = await this.apiService.getProductIndividualProductionValues(payload);
        const manualProductionValues = await this.apiService.getProductIndividualManualProductionValues(payload);
        this.appStore.dispatch(new LoadProductProductionValueAction(productionValues));
        this.appStore.dispatch(new LoadProductManualProductionValueAction(manualProductionValues));
    }

    async loadProductIndividual(productIndividualId: number): Promise<void> {
        const payload = { productIndividualId:_.toNumber(productIndividualId) };
        const productIndividuals = await this.apiService.getProductIndividuals(payload);
        if (productIndividuals !== null && productIndividuals.length > 0) {
            this.appStore.dispatch(new LoadProductIndividual(productIndividuals[0]));
        }
    }

    async findProductIndividual(chassisNumberOrProdIndNumber: number): Promise<ProductIndividual[]> {
        const payload = { chassisNumberOrProdIndNumber };
        return await this.apiService.findProductIndividual(payload);
    }

    async findArchiveProductIndividual(chassisNumberOrProdIndNumber: number): Promise<ProductIndividual[]> {
        const payload = { chassisNumberOrProdIndNumber };
        return await this.apiService.findArchiveProductIndividual(payload);
    }

    getProductProductionValues(): Observable<ComponentTypeProductionValue[]> {
        return this.appStore
            .select(state => state.productIndividual.componentTypeProductionValues);
    }

    getProductManualProductionValues(): Observable<ComponentTypeManualProductionValue[]> {
        return this.appStore
            .select(state => state.productIndividual.componentTypeManualProductionValues);
    }

    getProductIndividual(): Observable<ProductIndividual> {
        return this.appStore
            .select(state => state.productIndividual.productIndividual);
    }
}
